<template>
  <div class="completion-stocks">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách phiếu hoàn tất bảo hành'">
          <template v-slot:toolbar>
            <div class="row">
              <div class="action">
                <b-dropdown
                  size="sm"
                  right
                >
                  <template slot="button-content">
                    <i class="fas fa-cog text-1rem"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="onClickImportExcel">
                    <span>
                      <i class="far fa-file-excel text-1rem"></i>
                      &nbsp; Nhập Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div
                v-if="checkPermission('STOCK_INSERT')"
                class="ml-3"
              >
                <router-link to="/complete-warranty-stock/upsert-complete-warranty-stock">
                  <b-button
                    variant="primary"
                    size="sm"
                  >
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-add-1"
                    ></i>Thêm
                    mới
                  </b-button>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control-sm"
                    :config="dpConfigs.start"
                    v-model="dpForm.startDate"
                  ></date-picker>
                  <span class="mr-1 ml-1"></span>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control-sm"
                    :config="dpConfigs.end"
                    v-model="dpForm.endDate"
                  ></date-picker>
                </div>
              </b-col>
              <b-col>
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  placeholder="mã, tên, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProduct"
                  @change="onInputChangeProduct"
                  suggestionName="productName"
                />
              </b-col>
              <b-col>
                <Autosuggest
                  :model="searchStock"
                  :suggestions="filteredOptions"
                  placeholder="kho nguồn"
                  :limit="10"
                  @select="onSelected"
                  @change="onInputChange"
                  suggestionName="suggestionName"
                />
              </b-col>
              <b-col>
                <b-form-input
                  v-model="searchId"
                  type="text"
                  placeholder="Mã phiếu"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="Tìm kiếm theo nội dung"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                >Lọc</b-button>
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="stocks"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="10"
                ></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span
                    v-text="row.item.count"
                    class="text-right"
                  ></span>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <div
                  class="productCode"
                  @click="editItem(row.item)"
                  style="cursor: pointer"
                >
                  <span v-text="row.item.code"></span>
                </div>
              </template>
              <template v-slot:cell(requireStockCode)="row">
                <div
                  class="productCode"
                  @click="linkToRequireStock(row.item)"
                  style="cursor: pointer"
                >
                  <span v-text="row.item.requireStockCode"></span>
                </div>
              </template>

              <template v-slot:cell(createdAt)="row">
                <div style="text-align: center">
                  <span
                    v-text="row.item.createdAt"
                    class="text-right text-primary mb-0"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(sourceWarehouseId)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.sourceWarehouseId"></span>
                </div>
              </template>

              <template v-slot:cell(destinationWarehouseId)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.destinationWarehouseId"></span>
                </div>
              </template>
              <template v-slot:cell(description)="row">
                <div
                  @mouseover="hoverPrice = true"
                  @mouseleave="hoverPrice = false"
                >
                  <span
                    v-text="row.item.description"
                    class="mr-2"
                  ></span>
                  <span @click="handleShowNoteModel(row.item)">
                    <i
                      v-if="hoverPrice"
                      class="fas fa-edit"
                    ></i>
                  </span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown
                    size="sm"
                    id="dropdown-left"
                    no-caret
                    right
                  >
                    <template slot="button-content">
                      <i class="flaticon2-settings fs-6 pr-0"></i>
                    </template>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('STOCK_DELETE')"
                    >
                      <span class="text-dark fs-6">
                        <i class="flaticon2-rubbish-bin-delete-button fs-6 text-danger"></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->
            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số phiếu:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchStock"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
            <template>
              <b-modal
                v-model="showEditPriceModal"
                hide-footer
                :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label
                      class="labelInput"
                      for="inputOriginalPrice"
                    >Ghi chú</label>
                    <b-form-textarea
                      v-model="note"
                      placeholder="Nhập ghi chú..."
                      rows="5"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      class="font-weight-bold w-100"
                      variant="primary"
                      size="sm"
                      @click="updateNote"
                    >Lưu</b-button>
                    <b-button
                      class="ml-2 font-weight-bold w-100"
                      variant="secondary"
                      size="sm"
                      @click="handleShowNoteModel"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>
            </template>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { STOCK_SLIP_TYPE } from '@/utils/enum';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import debounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
} from '@/utils/common';
import timeUtils from '@/utils/date';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
    VclTable,
  },
  data() {
    return {
      STOCK_SLIP_TYPE,
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: null,
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      stocks: [],
      search: '',
      searchStock: '',
      searchStockId: '',
      searchDestinationStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: STOCK_SLIP_TYPE.CREATE_WARRANTY_COMPLETION_SLIP,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu chuyển kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho xuất',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [],
      filteredOptions: [],
      limit: 10,
      filteredDStockOptions: [],
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
    };
  },
  methods: {
    onClickImportExcel() {
      this.$router.push({
        name: 'import-warranty-stock',
      });
    },
    fetchStore: async function () {
      this.options = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          const store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options.push(store);
        });
        this.filteredOptions = [...this.options];
        this.filteredDStockOptions = [...this.options];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        searchDesciption: this.search,
        searchCode: this.searchId ? this.searchId.trim() : '',
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : '',
        searchStock: this.searchStockId,
        searchDestinationStock: null,
        type: this.type,
        searchProduct: this.searchProduct,
      };

      ApiService.query('stocks', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          let result = [];
          stockResponse.forEach((item, index) => {
            const counting =
              this.currentPage >= 2
                ? index + 1 + this.currentPage * 10 - 10
                : index + 1;
            let stock = {
              ...item,
              count: counting,
              status: this.status,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'upsert-complete-warranty-stock',
        query: { id: item.id },
      });
    },
    linkToRequireStock: function (item) {
      this.$router.push({
        name: 'list-require-stock',
        query: { code: item.requireStockCode },
      });
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
      this.searchStockId = option.item.id;
    },
    onInputChange(text = '') {
      this.searchStock = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1 ||
            removeAccents(item.shortName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu hoàn tất bảo hành!',
        text: 'Bạn có chắc muốn xóa phiếu hoàn tất bảo hành?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/warranty-completion-slip/${item.id}`)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchStock();
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
          }
        });
    },
    fetchProduct() {
      this.optionsProduct = [];
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
        this.fetchStock();
      });
    },
    debounceInput: debounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text = '') {
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'complete-warranty-stock',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      const data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-transfer-stockslip',
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chuyển kho', route: 'complete-warranty' },
      { title: 'Danh sách phiếu hoàn tất bảo hành' },
    ]);
  },
  created() {
    this.dpConfigs = timeUtils.DP_CONFIG;
    this.fetchStock();
    this.fetchStore();
  },
};
</script>
